import type { VueQueryPluginOptions } from '@tanstack/vue-query';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import '@total-typescript/ts-reset';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { createApp, h } from 'vue';
import { logout } from '~/composables/use-logout-mutation';
import { refreshToken } from '~/composables/use-refresh-token-mutation';
import { env } from '~/env';
import { client } from '~/plugins/client';
import { i18n } from '~/plugins/i18n';
import { pinia } from '~/plugins/pinia';
import { vuetify } from '~/plugins/vuetify';
import { useAuthenticationStore } from '~/stores/authentication';
import { setUserFavicon } from '~/utils/change-favicon';
import App from './App.vue';
import './plugins/parent-window-access-token';
import { router } from './router/routes';

import('./plugins/beamer').then((module) =>
  module.beamerInitialize({
    product_id: env.VITE_BEAMER_ID,
    url: false,
    args: {
      selector: '.beamerTrigger',
      force_button: false,
      button: false,
    },
  })
);
import('./plugins/gtm').then((module) =>
  module.initializeGTM({
    id: env.VITE_GTM_ID,
  })
);
import('./plugins/intercom').then((module) => {
  module.initializeIntercom({
    app_id: env.VITE_INTERCOM_APP_ID,
  });
});
import('./plugins/posthog').then((module) => {
  module.initializePosthog({
    api_key: env.VITE_POSTHOG_API_KEY,
    api_host: env.VITE_POSTHOG_API_HOST,
  });
});
import('./plugins/userflow').then((module) => {
  module.initializeUserflow({
    token: env.VITE_USERFLOW_TOKEN,
  });
});
import('./plugins/sentry').then((module) => {
  module.initializeSentry({
    app_id: env.VITE_SENTRY_APP_ID,
  });
});

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData, TValue> {
    align: 'start' | 'center' | 'end';
  }
}

// @ts-expect-error - globalThis is not defined in the types
globalThis.gqlClient = client;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const vueQueryPluginOptions = {
  queryClient,
} satisfies VueQueryPluginOptions;

export const app = createApp({
  setup() {
    return () => h(App);
  },
});

app.use(i18n);
app.use(pinia);
// app.use(QueryBuilder)
app.use(router);
app.use(VueQueryPlugin, vueQueryPluginOptions);
app.use(vuetify);

app.mount('#app');

// mixpanel.init(env.VITE_MIXPANEL_PROJECT_TOKEN, { debug: true })

/* router.beforeEach((to, from, next) => {
  if (env.PROD && !mixpanel.has_opted_out_tracking()) {
    const authenticationStore = useAuthenticationStore()
    const accountTypeId = match(authenticationStore?.account?.account_type)
      .with('BUSINESS', () => authenticationStore.account.business_id)
      .with('INFLUENCER', () => authenticationStore.account.influencer_id)
      .otherwise(() => authenticationStore.account.account_id)
    mixpanel.identify(authenticationStore?.auth.user.id)
    mixpanel.add_group(authenticationStore.account.account_type, accountTypeId)
    mixpanel.track('Page view', {
      userId: authenticationStore?.auth.user.id,
      name: to.name,
      path: to.path,
      query: to.query,
      params: to.params,
    })
  }
}) */

router.beforeEach(async (_to, from, next) => {
  const authenticationStore = useAuthenticationStore(pinia);

  const isExpired = authenticationStore.auth.expiresAt
    ? new Date(authenticationStore.auth.expiresAt).getTime() < Date.now()
    : false;

  if (isExpired && authenticationStore.auth.refreshToken) {
    refreshToken(authenticationStore.auth.refreshToken)
      .then(() => {
        next();
      })
      .catch((error) => {
        console.log(error);

        logout().catch((logoutError) => {
          console.log(logoutError);
        });

        authenticationStore.clearAuth();

        next({
          name: 'public.home',
          query: { redirect: from.fullPath },
        });
      });
  } else {
    next();
  }
});

router.beforeEach((_to, _from, next) => {
  const authenticationStore = useAuthenticationStore(pinia);

  if (authenticationStore.userType) {
    setUserFavicon(authenticationStore.userType);
  }

  return next();
});

router.onError((error, to) => {
  const knownErrors = [
    'Failed to fetch dynamically imported module', // Chrome
    'Importing a module script failed', // Safari
  ];
  const hasKnownError = knownErrors.some((knownError) => error.message?.includes(knownError));

  if (hasKnownError) {
    if (to.fullPath) {
      window.location.href = to.fullPath;
    } else {
      window.location.reload();
    }
  } else {
    console.error(error);
  }
});
